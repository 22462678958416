// frontend/src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import TodoList from './components/TodoList';
// import TodoForm from './components/TodoForm';
import './App.css';

function App() {
  // const [todos, setTodos] = useState([]);
  const [EmpData, setEmpData] = useState([]);

  const fetchEmpData = async () => {
    try {
      //const res = await axios.get('http://localhost:5000/api/todos');
      const res = await axios.get('/api');
      setEmpData(res.data);
      console.log(res.data);
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  useEffect(() => {
    // fetchTodos();
    fetchEmpData();
  }, []);


  return (
    <div className="App">
      {/* <h1>Todo App</h1>
      <TodoForm fetchTodos={fetchTodos} />
      <TodoList todos={todos} fetchTodos={fetchTodos} /> */}
      {/* <table>
        <th>Emp Name</th>
        <th>Email</th>
      </table>
      <tbody>
        {EmpData && EmpData.map((item) => 
          <tr>
            <td>{item.username}</td>
            <td>{item.email}</td>
          </tr>
        
        )}

      </tbody> */}
  <h1> Welcome to GDS Marriage Links  </h1>

    </div>
  );
}

export default App;
